// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, collection, getDocs } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCI962NUbAPALVBjsO7rHY7_xbfocJFJlo",
    authDomain: "fir-auth-altunit.firebaseapp.com",
    projectId: "fir-auth-altunit",
    storageBucket: "fir-auth-altunit.appspot.com",
    messagingSenderId: "42445591351",
    appId: "1:42445591351:web:c1d0a97a341638fef500b2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export const db = getFirestore()
export default app
