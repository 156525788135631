import React from "react";


function HTML() {
    return (
        <>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <h1 className="secTitle">HTML & CSS</h1>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
        </>
    );
}

export default HTML;
