import React from "react";


function ReactJS() {
    return (
        <>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <h1 className="secTitle">ReactJS</h1>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
        </>
    );
}

export default ReactJS;
