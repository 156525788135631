import React from "react";


function JavaScript() {
    return (
        <>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <h1 className="secTitle">JavaScript</h1>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
        </>
    );
}

export default JavaScript;
