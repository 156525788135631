import React, { useEffect, useState } from "react";
import {
    collection,
    doc,
    query,
    where,
    getDocs,
    onSnapshot,
    updateDoc,
    addDoc,
    getFirestore,
} from "firebase/firestore";
import { loadStripe } from "@stripe/stripe-js";
import db from "../../../../firebase"; // Zorg ervoor dat de Firebase-instantie correct wordt geïmporteerd
import { useNavigate } from "react-router-dom"; // Importeer useNavigate voor navigatie

function PlanScreen({ user }) {
    const [products, setProducts] = useState([]);
    const [subscriptions, setSubscriptions] = useState([]);
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [subscriptionToCancel, setSubscriptionToCancel] = useState(null);
    const [loadingProductId, setLoadingProductId] = useState(null); // Toegevoegd voor de loader

    const firestore = getFirestore(db); // Haal de Firestore-instantie op
    const navigate = useNavigate(); // Gebruik useNavigate voor navigatie

    useEffect(() => {
        if (user && user.uid) {
            const q = query(collection(firestore, "customers", user.uid, "subscriptions"));
            onSnapshot(q, (querySnapshot) => {
                const subs = [];
                querySnapshot.forEach(async (subscriptionDoc) => {
                    subs.push({
                        id: subscriptionDoc.id,
                        ...subscriptionDoc.data(),
                    });
                });
                setSubscriptions(subs);
            });
        }
    }, [user, firestore]);

    useEffect(() => {
        const q = query(collection(firestore, "products"), where("active", "==", true));
        onSnapshot(q, (querySnapshot) => {
            const products = {};
            querySnapshot.forEach(async (productDoc) => {
                products[productDoc.id] = productDoc.data();
                const productDocRef = doc(firestore, "products", productDoc.id);
                const priceSnap = await getDocs(collection(productDocRef, "prices"));
                priceSnap.forEach((price) => {
                    products[productDoc.id].prices = {
                        priceId: price.id,
                        priceData: price.data(),
                    };
                });
            });
            setProducts(products);
        });
    }, [firestore]);

    const loadCheckout = async (priceId, productId) => {
        setLoadingProductId(productId); // Loader wordt getoond
        const docRef = await addDoc(
            collection(firestore, "customers", user.uid, "checkout_sessions"),
            {
                price: priceId,
                success_url: window.location.origin,
                cancel_url: window.location.origin,
            }
        );

        onSnapshot(docRef, async (snap) => {
            const { error, sessionId } = snap.data();
            setLoadingProductId(null); // Loader wordt verborgen
            if (error) {
                alert(`An error occurred: ${error.message}`);
            }
            if (sessionId) {
                const stripe = await loadStripe("pk_live_51N9CgOAw7c0fvx0kEXlOmHx6Oqm9jIiB6L1Kfwv5zqMlxA1XaaMYekVpFGwg0c0o74JqZlVnoZII3xH9YMPbQgSO002mbw4Hkq");
                await stripe.redirectToCheckout({ sessionId });
            }
        });
    };

    const cancelSubscription = async () => {
        try {
            await updateDoc(doc(firestore, "customers", user.uid, "subscriptions", subscriptionToCancel), {
                cancel_at_period_end: true,
            });
            setSubscriptions((prevSubs) =>
                prevSubs.map((sub) =>
                    sub.id === subscriptionToCancel
                        ? { ...sub, cancel_at_period_end: true }
                        : sub
                )
            );
            alert("Your subscription will be canceled at the end of the current period.");
            setShowConfirmDialog(false);
            setSubscriptionToCancel(null);
        } catch (error) {
            console.error("Error canceling subscription: ", error);
            alert("Failed to cancel the subscription. Please try again.");
        }
    };

    const openConfirmDialog = (subscriptionId) => {
        setSubscriptionToCancel(subscriptionId);
        setShowConfirmDialog(true);
    };

    const closeConfirmDialog = () => {
        setShowConfirmDialog(false);
        setSubscriptionToCancel(null);
    };

    const startCourse = (module) => {
        navigate(`/${module}`);
    };

    return (
        <div className="plansScreen">
            <br />
            {Object.entries(products).map(([productId, productData]) => {
                const currentSubscription = subscriptions.find(
                    (sub) => productData.metadata?.role?.toLowerCase() === sub.role.toLowerCase()
                );

                return (
                    <div
                        key={productId}
                        className={`plansScreen__plan ${currentSubscription ? "plansScreen__plan--current" : ""}`}
                    >
                        <div className="plansScreen__info">
                            <h5>{productData.name}</h5>
                            <h6>{productData.description}</h6>
                            {currentSubscription && (
                                <>
                                    <p className="plansScreen__renewal">
                                        Renewal date:{" "}
                                        {new Date(currentSubscription.current_period_end.seconds * 1000).toLocaleString(
                                            "nl-NL",
                                            {
                                                weekday: "long",
                                                year: "numeric",
                                                month: "long",
                                                day: "numeric",
                                                hour: "2-digit",
                                                minute: "2-digit",
                                                second: "2-digit",
                                            }
                                        )}
                                    </p>
                                    {currentSubscription.cancel_at_period_end ? (
                                        <p className="cancelNotice">
                                            Your subscription will be canceled at the end of the current period.
                                        </p>
                                    ) : (
                                        <button
                                            onClick={() => openConfirmDialog(currentSubscription.id)}
                                            className="cancelButton"
                                        >
                                            Cancel Subscription
                                        </button>
                                    )}
                                    {productData.name.toLowerCase() === "module 1: html & css" && (
                                        <button onClick={() => startCourse("HTML")} className="startButton">
                                            Start Cursus
                                        </button>
                                    )}
                                    {productData.name.toLowerCase() === "module 2: javascript" && (
                                        <button onClick={() => startCourse("JavaScript")} className="startButton">
                                            Start Cursus
                                        </button>
                                    )}
                                </>
                            )}
                        </div>
                        <button
                            onClick={() =>
                                !currentSubscription && loadCheckout(productData.prices.priceId, productId)
                            }
                            disabled={!!currentSubscription}
                            className={`btn ${currentSubscription ? "planButton--disabled" : ""} ${loadingProductId === productId ? "loading" : ""}`}
                        >
                            {loadingProductId === productId ? (
                                <div className="loader">Laden...</div>
                            ) : (
                                currentSubscription ? "Current Plan" : "Abonneer"
                            )}
                        </button>
                    </div>
                );
            })}

            {showConfirmDialog && (
                <div className="confirmDialog">
                    <p>Are you sure you want to cancel your subscription? This action cannot be undone.</p>
                    <button onClick={cancelSubscription} className="confirmButton">Yes, Cancel</button>
                    <button onClick={closeConfirmDialog} className="cancelButton">No, Keep</button>
                </div>
            )}
        </div>
    );
}

export default PlanScreen;


