import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "../../../context/AuthContext";
import { collection, onSnapshot, query } from "firebase/firestore";
import { db } from "../../../firebase";
import PlanScreen from "./PlanScreen/PlanScreen";
import "./Account.scss";

function Account() {
    const { user, logout } = UserAuth();
    const navigate = useNavigate();
    const [currentSubscriptions, setCurrentSubscriptions] = useState([]);
    const [expiredSubscriptions, setExpiredSubscriptions] = useState([]);

    useEffect(() => {
        if (user && user.uid) {
            const q = query(collection(db, "customers", user.uid, "subscriptions"));
            onSnapshot(q, (querySnapshot) => {
                const currentSubs = [];
                const expiredSubs = [];
                const now = new Date();

                querySnapshot.forEach((subscription) => {
                    const data = subscription.data();
                    console.log("Subscription data: ", data); // Log de data om te zien welke velden beschikbaar zijn

                    const renewalDate = data.current_period_end && data.current_period_end.seconds
                        ? new Date(data.current_period_end.seconds * 1000)
                        : null;

                    const formattedDate = renewalDate ? renewalDate.toLocaleDateString("nl-NL", {
                        day: 'numeric',
                        month: 'long',
                        year: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric',
                    }) : "Datum niet beschikbaar";

                    const subscriptionInfo = {
                        role: data.role,
                        renewalDate: formattedDate,
                    };

                    if (renewalDate && renewalDate > now) {
                        currentSubs.push(subscriptionInfo);
                    } else {
                        expiredSubs.push(subscriptionInfo);
                    }
                });

                currentSubs.sort((a, b) => a.role.localeCompare(b.role)); // Sorteer de huidige abonnementen op alfabetische volgorde
                expiredSubs.sort((a, b) => a.role.localeCompare(b.role)); // Sorteer de verlopen abonnementen op alfabetische volgorde

                setCurrentSubscriptions(currentSubs);
                setExpiredSubscriptions(expiredSubs);
            });
        }
    }, [user]);

    const handleLogout = async () => {
        try {
            await logout();
            navigate("/login");
            console.log("You are logged out");
        } catch (e) {
            console.log(e.message);
        }
    };

    return (
        <div className="auth container section">
            <div className="secContainer">
                <div data-aos="fade-up" data-aos-duration="2000" className="single">
                    <span className="secTitle">Welkom</span>
                </div>

                <div className="authContainer">
                    <div data-aos="fade-up" data-aos-duration="2000" className="single">
                        <p>Je bent ingelogd met: {user && user.email}</p>

                        <div className="profileScreen__plansHeading">
                            <h5>Huidige Abonnementen:</h5>
                     <p>     {currentSubscriptions.length > 0 ? (
                                <ul>
                                    {currentSubscriptions.map((subscription, index) => (
                                        <li key={index}>
                                            {subscription.role}: {subscription.renewalDate}
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p>Geen huidige abonnementen gevonden</p>
                            )}
                     </p>
                        </div>

                        <div className="profileScreen__plansHeading">
                            <h5>Verlopen Abonnementen:</h5>
                            {expiredSubscriptions.length > 0 ? (
                                <ul>
                                    {expiredSubscriptions.map((subscription, index) => (
                                        <li key={index}>
                                            {subscription.role}: {subscription.renewalDate}
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <p>Geen verlopen abonnementen gevonden</p>
                            )}
                        </div>

                        {user && <PlanScreen user={user} />}

                        <button onClick={handleLogout} className="btn">
                            Logout
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Account;



